angular.module('fingerink')
    .config(function config($stateProvider) {
        $stateProvider.state('public.register', {
            url: '/register?planId',
            views: {
                "public": {
                    controller: 'RegisterCtrl',
                    templateUrl: 'web/public/register/register.tpl.html',
                    controllerAs: 'controller'
                }
            },
            data: {
                pageTitle: 'Registration'
            }
        });
    })



    .controller('RegisterCtrl', function ($rootScope, $scope, loginService, session, fingerinkService, $stateParams, $state, $location, $q, $window, $translate, $http, productService) {
        var that = this;
        that.domainTo = $rootScope.domainQueDeberiaIr;
        that.domain = $rootScope.dominio;
        that.doingRegister = false;
        that.step = 1;
        that.register = {
            user: {
                useNewsLetter: true,
                company: {
                    domain: $rootScope.dominio,
                    particular: true,
                    roi: false
                }
            }
        };


        function checkDominioCorrecto(paises, location, dominio) {
            for (var i = 0; i < paises.length; i++) {
                if (location.country == paises[i].countryCode) {
                    that.register.user.company.country = paises[i];
                    if (paises[i].domain == dominio) {
                        return;
                    } else {
                        that.domainQueDeberiaIr = paises[i];
                    }
                }
            }
        }

        var promises = [productService.getProducts(), productService.getProductsPrices(), fingerinkService.getCountries(), $http({
            url: 'https://ipapi.co/json/'
        })];
        $q.all(promises).then(function (response) {

            var productos = response[0].data;
            that.precios = response[1].data;
            
            var paises = response[2].data;
            var location = response[3].data;
            
            that.productosMap = [];
            for (var i = 0; i < productos.length; i++) {
                if (productos[i].id == $stateParams.planId && productos[i].isPlan) {
                    that.register.plan = response[0].data[i];
                    break;
                }
                if (productos[i].id == 1 && !that.register.isPlan) {
                    that.register.plan = productos[i];
                }

                that.productosMap[productos[i].id] = productos[i];

            }


            that.paisesMap = [];
            for (var l = 0; l < paises.length; l++) {
                that.paisesMap[paises[l].id] = paises[l];
            }
            checkDominioCorrecto(paises, location, $rootScope.dominio);
            that.paises = paises;
            that.loaded = true;

        }, function () {

        });









        that.registerDraft = function () {
            that.doingDraftRegister = true;
            that.register.user.password = md5(that.register.user.password);
            loginService.registro(that.register.user).then(function (response) {
                that.register.user = response.data;
                /* if (that.register.plan.id == 1) {
                     that.doRegister();
                 } else {*/
                that.doingDraftRegister = false;
                that.step = 2;
                // }
            }, function (response) {
                if (response.data && response.data.errorsHTTP == "USER_REGISTERED") {
                    swal($translate.instant('¡Usuario ya registrado!'), $translate.instant("El email introducido ya tiene una cuenta de Fingerink asociada"), "error");
                } else {
                    swal($translate.instant('Algo ha ido mal'), $translate.instant("Ha habido un error durante el registro"), "error");
                }
                that.doingDraftRegister = false;
            });
        };

        $scope.$watch('controller.step', function () {
            that.register.user.password = '';
            that.register.user.repeatPassword = '';
        });



        that.doRegister = function (payment) {
            if (!that.doingRegister) {
                that.doingRegister = true;
                loginService.registroFinal(that.register).then(function (response) {
                    if (response.status === 200) {
                        that.doingRegister = false;
                        session.put(response.data);
                        $state.go('main.dashboard');
                        if (FingerinkEnvironmentAngular.trackGoogleFacebookTags) {
                            if (that.register.plan && that.register.plan.id) {
                                if (that.register.plan.id == 18) {
                                    gtag('event', 'conversion', {
                                        'send_to': 'AW-811631399/oTT3CNjN_YABEKeGgoMD'
                                    });
                                } else if (that.register.plan.id == 19) {
                                    gtag('event', 'conversion', {
                                        'send_to': 'AW-811631399/EbUaCJ3L_YABEKeGgoMD'
                                    });
                                } else {
                                    gtag('event', 'conversion', {
                                        'send_to': 'AW-811631399/6yBZCOjF_YABEKeGgoMD'
                                    });
                                }
                            } else {
                                gtag('event', 'conversion', {
                                    'send_to': 'AW-811631399/6yBZCOjF_YABEKeGgoMD'
                                });
                            }
                            fbq('track', 'CompleteRegistrationPayment');
                            $window.ga('send', {
                                hitType: 'event',
                                eventCategory: 'Register',
                                eventAction: 'register',
                                eventLabel: that.register.plan.id
                            });
                        }
                    } else {
                        that.doingRegister = false;
                        swal($translate.instant('Algo ha ido mal'), $translate.instant('No se ha podido realizar el registro correctamente'), 'error');
                    }
                }, function () {
                    that.doingRegister = false;
                    swal($translate.instant('Algo ha ido mal'), $translate.instant('No se ha podido realizar el registro correctamente'), 'error');
                });
            }
        };
    });
